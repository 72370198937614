.instrucion_step{
    padding: 10px;
    text-align: justify;
    display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	align-items: baseline;
	align-content: stretch;
}

.description-title {
    font-weight: bold;
}

.instruction_content * {
	all: revert;
}

.start-buttons {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	align-content: center;
} 

.start-buttons > button {
	margin-left: 5px;
}