.register-breadcrumb-area {
    background: rgba(76, 175, 80, 0.3); 
    background-color: #31496b;
    height: 300px;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    text-align: center;
    z-index: 2;
    color: #fff;
    background-size: cover;
    background-position: center;
}



