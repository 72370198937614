.test-bar {
    font-weight: bold;
    text-transform: uppercase;
    background-color: #ff0000;
    color: #fff !important;
    font-size: 25px;
    text-align: center;
    top: 0px;
    left: 0px;
    width: 100%;
    z-index: 99999;
    position: sticky;
    
}