.question-container{
	padding-left: 10%;
	padding-right: 10%;
	padding-top: 3%;
	padding-bottom: 3%;
	margin-bottom: 10px;
	background-color: #233D63;
}

.quiz-content{
	padding-top: 40px;
	align-items: center;
	justify-content: space-between;
}

.question-area{
    display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: flex-start;
	align-content: stretch;
}

.question-wording{
	width: 65%;
}

.quiz-desc-no-image {
	width: 100% !important;
}

.question-description p {
	white-space: break-spaces;
	text-align: justify;
}

.question-image{
	padding-top: 10px;
	width: 30%;
}

.small-question-image {
	max-height: 30vmin;
}

@media only screen and (max-width: 600px){

	.question-wording{
		width: 100%;
	}

	.question-image{
		padding-top: 10px;
		width: 100%;
	}

	.btn-skip {
		width: 32%;
	}
	
	.btn-next-question {
		width: 65%;
	}

}
