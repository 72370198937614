.quiz-ans-wrap{
    min-height: 611px;
}

.breadcrumb-area-common {
    background-color: #233d63;
    height: 300px;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    text-align: center;
    z-index: 2;
    color: #fff;
    background-size: cover;
    background-position: center;
}

.theme-btn-light-custom {
    color: #7f8897;
    padding-right: 20px;
    padding-left: 20px;
    line-height: 45px;
    background-color: rgba(127, 136, 151, 0.1);
    border: 1px solid rgba(127, 136, 151, 0.2);
    text-transform: none !important;
}
