.answer-option{
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
        align-content: stretch;
    }

.answer-option img {
    height: 100px;
}

.answer-letter {
    font-size: 20px;
    margin-right: 5px;
}
